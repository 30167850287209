import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

const useShowPage = () => {
  const navigate = useNavigate()
  const timerId = useRef(null)

  const showCoursePage = () => {
    const courseId = localStorage.getItem('courseToOpen')
    const showCoursePageAfterPoll = localStorage.getItem('showCoursePageAfterPoll')
    if (courseId && !showCoursePageAfterPoll) {
      localStorage.removeItem('courseToOpen')
      timerId.current = setTimeout(() => navigate(`/academy/course/${courseId}`, { replace: true }))
    }
  }

  useEffect(() => () => clearTimeout(timerId.current), [])

  return { showCoursePage }
}

export default useShowPage
