import { Link } from 'react-router-dom'
import { useDrag, useDragLayer } from 'react-dnd'
import { Tag, Progress, List } from 'antd'
import { videoStatuses, getRenderingTime } from '../../utils/videos'
import draft_video_thumbnail from '../../assets/images/draft-video-thumbnail.jpg'
import VideoItemActionMenu from './videoItemActionMenu'

const VideoItem = ({
  video,
  isDeletedVideos,
  isVideoCreating,
  setIsVideoCreating,
  activeItem,
  setActiveItem,
  fetchVideos,
  fetchFolders,
  filterInfo,
  showRestoringConfirm,
}) => {
  const [{ opacity }, drag] = useDrag(
    () => ({
      type: 'video',
      item: video,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [video],
  )

  const { itemType } = useDragLayer((monitor) => ({
    itemType: monitor.getItemType(),
  }))

  return (
    <List.Item className="video-item">
      <VideoItemActionMenu
        video={video}
        isDeletedVideos={isDeletedVideos}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        fetchFolders={fetchFolders}
        fetchVideos={fetchVideos}
        filterInfo={filterInfo}
        isVideoCreating={isVideoCreating}
        setIsVideoCreating={setIsVideoCreating}
      />
      <Link
        to={isDeletedVideos ? false : `/video/${video._id}`}
        onClick={() => isDeletedVideos && showRestoringConfirm(video._id)}
      >
        <div
          ref={drag}
          style={{ opacity }}
          className={`video-item-content ${video.status === 'rendering' ? 'render' : ''} ${
            itemType === 'video' ? 'dragging' : ''
          }`}
        >
          <Tag
            color={videoStatuses[video.status]?.color || videoStatuses.draft}
            className="card-tag positioned-absolute"
          >
            {videoStatuses[video.status]?.title || 'Unsupported'}
          </Tag>
          <div className="img-wrapper">
            {video.thumbnail ? (
              <img
                className={`${video.data?.format === '9_16' ? 'vertical' : ''} ${
                  video.data?.format === '1_1' ? 'square' : ''
                }`}
                src={video.thumbnail}
              />
            ) : (
              <img src={draft_video_thumbnail} />
            )}
            {video.status === 'rendering' && (
              <div className="mask">
                <div>
                  <p>{`${getRenderingTime(video).minutesLeftFormatted} left`}</p>
                  <Progress
                    percent={getRenderingTime(video).percent}
                    showInfo={false}
                    status="active"
                    size="small"
                    strokeColor="#4868FF"
                    trailColor="#ffffff"
                  />
                </div>
              </div>
            )}
          </div>
          <p>{video.name}</p>
        </div>
      </Link>
    </List.Item>
  )
}

export default VideoItem
