import { uniqueId } from './helpers'
import { SyncOutlined, CheckCircleOutlined, CloseCircleOutlined, HourglassOutlined } from '@ant-design/icons'
import { hideElaiMentions, defaultAvatarCode } from './config'

const defaultAvatarConfigs = {
  'gia.casual': {
    code: 'gia.casual',
    name: 'Gia Casual',
    canvas: 'https://d3u63mhbhkevz8.cloudfront.net/common/gia/casual/gia_casual.png',
    gender: 'female',
    limit: 300,
  },
  'amanda.business': {
    code: 'amanda.business',
    name: 'Amanda Business',
    canvas: 'https://d3u63mhbhkevz8.cloudfront.net/common/amanda/amanda_business_low.png',
    gender: 'female',
  },
}

const defaultAvatar = defaultAvatarConfigs[defaultAvatarCode]

const videoStatuses = {
  draft: { title: 'Draft', color: '#31353b', icon: null },
  validating: { title: 'Validate', color: '#c971ff', icon: <SyncOutlined /> },
  rendering: { title: 'Rendering', color: '#c971ff', icon: <SyncOutlined spin /> },
  ready: { title: 'Ready', color: '#4868ff', icon: <CheckCircleOutlined /> },
  error: { title: 'Error', color: 'error', icon: <CloseCircleOutlined /> },
  moderation: { title: 'Moderation', color: 'warning', icon: <HourglassOutlined /> },
}

const blankSlide = () => ({
  id: uniqueId(),
  speech: hideElaiMentions ? '' : 'Welcome to Elai! Type your script and click "Render" to generate your first video!',
  avatar: defaultAvatar,
  language: 'English',
  voice: 'en-US-JennyNeural:cheerful',
  voiceProvider: 'azure',
  voiceType: 'text',
  animation: 'fade_in',
  canvas: {
    version: '4.4.0',
    background: '#ffffff',
    objects: [
      {
        type: 'avatar',
        left: 151.5,
        top: 36,
        width: 1080,
        height: 1080,
        fill: '#4868FF',
        scaleX: 0.3,
        scaleY: 0.3,
        src: defaultAvatar.canvas,
        avatarType: 'transparent',
        animation: {
          type: null,
          exitType: null,
        },
      },
    ],
  },
})

const getDefaultVoice = (voices, gender, language) => {
  const voiceObject = voices[language ? voices.findIndex((l) => l.name === language) : 0][gender][0]

  return {
    voice: voiceObject.defaultStyle ? `${voiceObject.voice}:${voiceObject.defaultStyle}` : voiceObject.voice,
    voiceProvider: voiceObject.voiceProvider,
  }
}

// duration should be a number of seconds
const formatSeconds = (secDuration, fullText) => {
  if (!secDuration) return ''
  const minutesDesc = fullText ? ' minutes' : 'm'
  const secondsDesc = fullText ? ' seconds' : 's'

  const minutes = Math.floor(secDuration / 60)
  const seconds = Math.round(secDuration - minutes * 60)

  const minutesText = minutes > 0 ? `${minutes}${minutesDesc}` : ''
  const secondsText = seconds > 0 ? `${seconds}${secondsDesc}` : ''

  return secDuration < 60 ? `${secondsText}`.trim() : `${minutesText} ${secondsText}`.trim()
}

// duration should be a number of minutes
const formatMinutes = (minutesDuration, fullText = false) => {
  if (!minutesDuration) return ''
  const minutesDesc = fullText ? ' minutes' : 'm'
  const hoursDesc = fullText ? ' hours' : 'h'

  const hours = Math.floor(minutesDuration / 60)
  const minutes = Math.round(minutesDuration - hours * 60)
  const hoursText = hours > 0 ? `${hours}${hoursDesc}` : ''
  const minutesText = minutes > 0 ? `${minutes}${minutesDesc}` : ''

  return minutesDuration < 60 ? `${minutesText}`.trim() : `${hoursText} ${minutesText}`.trim()
}

// if slide has no canvas it means it was pulled in a list from backend. in this case it should have hasAvatar marking
const hasAvatar = (slide) =>
  'hasAvatar' in slide
    ? slide.hasAvatar
    : slide.canvas?.objects?.some((obj) => obj.type === 'avatar' && obj.avatarType !== 'voiceover')

const getRenderingTime = (video) => {
  let minutesLeft = 0
  let total = 0

  const minutesFromLaunch = (new Date() - new Date(video.data.renderStartedAt)) / 60000
  const minutesFromLastUpdate = (new Date() - new Date(video.updatedAt)) / 60000
  const waitingTime = video.data.waitingTime

  // calc longest slides to be rendered one after another, considering that simuntaneusly we will render on all AVAILABLE SERVERS
  const slidesWithAvatars = { total: 0, duration: 0 }
  let ifRenderStarted = minutesFromLaunch > waitingTime
  let ifAllSlidesReady = true
  let avatarsType

  const is4k = video.data?.resolution === '4K'

  video.slides?.forEach((slide) => {
    // put all slides with avatar in queue whatever status is
    if (hasAvatar(slide)) {
      if (slide.status !== 'ready') ifAllSlidesReady = false
      // if slide is ready we dont add to slidesWithAvatars
      if (['avatarReady', 'renderingSlide', 'ready'].includes(slide.status)) {
        ifRenderStarted = true
        return
      }

      slidesWithAvatars.total++
      slidesWithAvatars.duration += slide.duration || slide.approxDuration || 15
      if (!avatarsType && ['mascot', 'photo'].includes(slide.avatar?.type)) avatarsType = slide.avatar.type
    }
  })

  // if there are no slides with avatars we consider that render started
  if (!slidesWithAvatars.total) ifRenderStarted = true

  /**
   * 1. Waiting time - add only if no rendering started or passed less minutes than waitingTime
   */
  if (waitingTime) {
    if (ifRenderStarted) {
      total += waitingTime
    } else {
      total += waitingTime
      minutesLeft += waitingTime - minutesFromLaunch
    }
  }

  /* 2. renderingAvatar+renderingSlide
   * total: avatars rendering time + average slide render time
   * left: current time() - (waitingTime + renderStarted + total)
   */
  if (slidesWithAvatars.total) {
    // avatar render coef x1.3/60 (0.022), 14 servers
    // photo avatar coef x11/60 (0.18), 3 servers
    // mascot avatar coef x6/60 (0.08), 1 server
    let coef = avatarsType === 'mascot' ? 0.08 : avatarsType === 'photo' ? 0.18 : 0.022
    let servers = avatarsType === 'mascot' ? 1 : avatarsType === 'photo' ? 3 : 14
    const renderingQueueRenderTime =
      (slidesWithAvatars.duration * coef) / Math.min(slidesWithAvatars.total, servers) +
      (slidesWithAvatars.duration / slidesWithAvatars.total / 60) * (is4k ? 0.9 : 0.5)
    total += renderingQueueRenderTime
    if (ifRenderStarted) {
      minutesLeft += Math.max(
        minutesFromLaunch > waitingTime
          ? renderingQueueRenderTime - (minutesFromLaunch - waitingTime)
          : renderingQueueRenderTime - minutesFromLastUpdate,
        0,
      )
    } else minutesLeft += renderingQueueRenderTime
  } else {
    // if no avatars to render just count slide-render time assuming max 3 slides simuntaneusly. Speed 0.5x, 0.9x for 4K
    video.slides?.forEach((slide) => {
      if (slide.status !== 'ready') {
        const slideRender = ((slide.duration || slide.approxDuration || 15) / 60) * (is4k ? 0.9 : 0.5)
        minutesLeft += slideRender
        total += slideRender
      }
    })
  }

  /**
   * 3. Final render. Speed 0.5x, 0.9x for 4K
   */
  const duration = video.slides.reduce((dur, slide) => dur + (slide.duration || slide.approxDuration || 15), 0) / 60
  const transitions = video.slides.filter((s) => s.transition).length
  const finalRender = (duration / ((8 * 0.6) / Math.max(1, Math.log(transitions)))) * (is4k ? 0.9 : 0.5)

  total += finalRender
  minutesLeft += ifAllSlidesReady ? finalRender - minutesFromLastUpdate : finalRender
  // console.log({ minutesLeft, total, ifRenderStarted, minutesFromLaunch, slidesWithAvatars, avatarsType })

  if (minutesLeft < 0) minutesLeft = 0.1

  return {
    minutesLeft,
    minutesLeftFormatted: formatMinutes(minutesLeft) || '1m',
    percent: 100 - (minutesLeft / total) * 100,
  }
}

const syncSlideItemsWithOriginState = (video) => {
  if (video.slides)
    video.slides.forEach(
      (s) =>
        s.canvas?.objects &&
        s.canvas.objects.forEach((o) => {
          if (!o.visible && o.type !== 'avatar') o.visible = true

          if (o.originState) {
            Object.assign(o, o.originState)
            delete o.originState
          }
        }),
    )
  return video
}

export { blankSlide, getDefaultVoice, videoStatuses, getRenderingTime, formatSeconds, syncSlideItemsWithOriginState }
