import { useStore } from '../store'
import { useEffect, useMemo, useState } from 'react'

import { languageDurationCoefficient } from '../data/languageDurationCoefficients'

const DEFAULT_DURATION_COEFFICIENT = 16
const WHITESPACES_REGEX = /(\r\n|\n|\r|[\ufe00-\ufe0f\u200B-\u200D\uFEFF\u00A0])/gm

export const useSlideDuration = ({ slide = {} }) => {
  const [allowLong, setAllowLong] = useState(false)

  const voices = useStore((stores) => stores.voicesStore.voices)
  const { account } = useStore((stores) => stores.authStore.user)

  const isVoiceOver = slide.canvas?.objects?.find((o) => o.type === 'avatar')?.avatarType === 'voiceover'

  useEffect(() => {
    setAllowLong(account.status === 'paid' && account.plan !== 'basic')
  }, [account?.status, account?.plan])

  const getVoiceRate = ({ voice, language, voiceProvider } = {}) => {
    const languageScope = voices.find((lang) => lang.name === language)
    // wsl has voice.playedTags, so find voice by it
    let foundVoice = languageScope?.male?.find(
      (v) => v.voiceProvider === voiceProvider && (v.voice === voice || v.playedTags?.some((tag) => tag.id === voice)),
    )
    if (!foundVoice) {
      foundVoice = languageScope?.female?.find(
        (v) =>
          v.voiceProvider === voiceProvider && (v.voice === voice || v.playedTags?.some((tag) => tag.id === voice)),
      )
    }
    return foundVoice?.approxDurationCoeficient ?? languageDurationCoefficient[language] ?? DEFAULT_DURATION_COEFFICIENT
  }

  // can be used without slide prop like const { getApproxDuration } = useSlideDuration({})
  const getApproxDuration = ({ speech, language, voiceProvider, voice }) => {
    if (!speech) return 0
    const punctuationMarksDuration = 0.3
    const commaMarkDuration = 0.3
    const punctuationMarks = /[?.!]/g
    speech = speech.replaceAll(WHITESPACES_REGEX, '').replaceAll(/<[^b][^>]*>?/gm, '')

    let breaksDuration = 0
    for (const match of speech.matchAll(/<break time=["']([0-9.]+)s["'] \/>/gm)) {
      const [, pause] = match
      breaksDuration += parseFloat(pause)
    }

    // removing all tags as they can influence for the punctuation count
    speech = speech.replaceAll(/<[^>]*>?/gm, ' ')

    const commasCount = speech.match(/,/g) ? speech.match(/,/g).length : 0
    const punctuationMarksCount = speech.match(punctuationMarks) ? speech.match(punctuationMarks).length : 0
    const punctuationDuration = commasCount * commaMarkDuration + punctuationMarksCount * punctuationMarksDuration

    // clean up speech
    speech = speech
      .replaceAll(/[-{}()"_?!,:;.“”<>`~|\\[\]]/gm, '')
      .replaceAll(/\s+/gm, ' ')
      .trim()
    const rate = getVoiceRate({ voice, language, voiceProvider })
    return +(speech.length / rate + breaksDuration + punctuationDuration).toFixed(2)
  }

  const getSlideMaxDuration = () => {
    // for voiceovers we don't need to check avatar.
    if (isVoiceOver || !slide.avatar) return allowLong ? 300 : 60
    return allowLong && slide.avatar.limit > 60 ? slide.avatar.limit : 60
  }

  const slideDurationOrApprox = useMemo(() => {
    const { duration, speech, language, voiceProvider, voice } = slide
    if (duration) return +duration.toFixed(1)
    return +getApproxDuration({ speech, language, voiceProvider, voice }).toFixed(1)
  }, [slide.voice, slide.voiceProvider, slide.language, slide.speech, slide.duration])

  const slideDurationLimit = useMemo(() => {
    return getSlideMaxDuration()
  }, [slide.avatar?.limit, allowLong, isVoiceOver])

  const slideDurationApproxLimit = useMemo(() => {
    const limit = getSlideMaxDuration()
    // if limit is more than 200 subtract by 15s (300 - 15 = 285)
    return limit > 200 ? limit - 15 : limit
  }, [slide.avatar?.limit, allowLong, isVoiceOver])

  return { slideDurationOrApprox, slideDurationApproxLimit, slideDurationLimit, getApproxDuration }
}
