import { useEffect, useState } from 'react'
import { Button, Card } from 'antd'
import { useNavigate } from 'react-router-dom'

import { StepsComponent } from './components'

import { useStore } from '../../../store'
import { createSniperLink } from '../../../utils/account'
import { showPlanCheckoutPage } from '../../../utils/payment'

import useShowPage from '../../../hooks/useShowPage'

import { rightOutlinedIcon } from './constants'

import steps_shape from '../../../assets/images/steps-shape.png'
import hand_icon from '../../../assets/images/hand-icon.png'

import './poll.less'

export const Poll = () => {
  const navigate = useNavigate()

  const user = useStore(({ authStore }) => authStore.user)
  const [currentStep, setCurrentStep] = useState(0)

  const { showCoursePage } = useShowPage()

  const navigateToHome = () => {
    localStorage.removeItem('isJustRegistered')
    navigate('/', {
      state: { isJustRegistered: true },
    })
  }

  /**
   * Works only for users who opened the application from the link with parameter 'purchase'
   */
  const handleShowPlanCheckoutPage = () => {
    localStorage.removeItem('showCheckoutPageAfterPoll')
    showPlanCheckoutPage()
  }

  /**
   * Show course page after signup
   */
  const handleShowCoursePage = () => {
    localStorage.removeItem('showCoursePageAfterPoll')
    showCoursePage()
  }

  useEffect(() => {
    if (currentStep === 'final') {
      const layout = document.getElementsByClassName('layout')[0]
      if (layout) {
        layout.style.height = '100vh'
      }

      const purchaseData = localStorage.getItem('purchase')
      const courseToOpen = localStorage.getItem('courseToOpen')
      if (purchaseData || courseToOpen) navigateToHome()
    }
  }, [currentStep])

  useEffect(() => {
    if (!user || user === null) {
      navigate('/signup')
    }
    return () => {
      handleShowPlanCheckoutPage()
      handleShowCoursePage()
    }
  }, [])

  return (
    <div className="poll-wrapper">
      <Card className={`signup-poll ${currentStep === 'final' && 'last-poll-step'}`}>
        {currentStep === 'final' ? (
          <div>
            <div className="card-title last-step-title">
              <h3>
                Wonderful
                <img src={hand_icon} className="hand-icon-img" alt="Thumb up icon" />
              </h3>
              <p className="last-step-text">
                Now, check your mailbox to confirm your email and create your first video!
              </p>
            </div>
            <div className="btns-final-container">
              <Button
                type="primary"
                icon={rightOutlinedIcon}
                className="btn-login btn-arrow last-step-button"
                size="large"
                href={createSniperLink(user.email)}
                target="_blank"
                rel="noreferrer"
              >
                Check mailbox
              </Button>
              <Button
                icon={rightOutlinedIcon}
                className="btn-login btn-arrow last-step-button"
                size="large"
                onClick={navigateToHome}
              >
                Skip for now
              </Button>
            </div>
          </div>
        ) : (
          <StepsComponent
            name={user.name}
            isPersonal={user.account.additionalInfo?.useType === 'Personal'}
            isPublicEmail={user.account.additionalInfo?.isPublicEmail}
            redirectUrl={localStorage.getItem('redirectUrl')}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        )}
        <img src={steps_shape} className="steps-shape-img" />
      </Card>
    </div>
  )
}
