import { useEffect, useMemo, useState } from 'react'

import { hasObjectNestedObjectTypes, textTypes } from '../../../../utils/canvas/canvas'

const typesCantBeInInteractiveGroup = ['gif', 'lottie', 'video']

export const useElementsState = (props) => {
  const { canvasActiveObject, setActiveObjectModifier } = props

  const [isOpenGradient, setIsOpenGradient] = useState(false)

  const activeObjectProps = useMemo(
    () => (canvasActiveObject?.type === 'activeSelection' ? canvasActiveObject.getObjects()[0] : canvasActiveObject),
    [canvasActiveObject],
  )

  const isActiveObjectText = useMemo(
    () =>
      canvasActiveObject?.type === 'activeSelection'
        ? canvasActiveObject.getObjects().every((obj) => textTypes.includes(obj.type))
        : textTypes.includes(canvasActiveObject?.type),
    [canvasActiveObject, canvasActiveObject?.type],
  )

  const selectionWithAvatar = useMemo(() => {
    return (
      canvasActiveObject?.type === 'activeSelection' &&
      canvasActiveObject?.getObjects()?.some((obj) => obj.type === 'avatar' || obj.meta?.listeningAvatar)
    )
  }, [canvasActiveObject])

  const objectTypeInGroupCantBeInteractive = useMemo(() => {
    return hasObjectNestedObjectTypes(canvasActiveObject, typesCantBeInInteractiveGroup)
  }, [canvasActiveObject, canvasActiveObject?._objects?.length])

  useEffect(() => {
    setIsOpenGradient(activeObjectProps?.fill?.type)
  }, [activeObjectProps?.fill])

  const handleBackgroundApply = () => setActiveObjectModifier({ background: 'apply' })

  const handleColorChange = (color) => setActiveObjectModifier({ change: 'fill', value: color })

  const handleBackgroundChanges = () => setActiveObjectModifier({ background: 'save' })
  const handleDetachBackground = () => setActiveObjectModifier({ background: 'detach' })

  // As it possible can be pricy and it calls inside cycle - memoize it for later use
  const canvasObjects = useMemo(() => {
    if (canvasActiveObject?.getObjects) {
      return canvasActiveObject.getObjects()
    }
  }, [canvasActiveObject, canvasActiveObject?.getObjects])

  return {
    canvasObjects,
    isOpenGradient,
    setIsOpenGradient,
    activeObjectProps,
    handleColorChange,
    isActiveObjectText,
    selectionWithAvatar,
    handleBackgroundApply,
    handleDetachBackground,
    handleBackgroundChanges,
    objectTypeInGroupCantBeInteractive,
  }
}
