import axios from 'axios'
import { useState, useEffect, useRef } from 'react'
import { UserOutlined, MailOutlined } from '@ant-design/icons'

import { Layout, Table, Button, Modal, Form, Input, Tooltip, Typography, Select } from 'antd'
import { useStore } from '../../store'
import Icon from '../../components/Icon'
import { request } from '../../utils/api'
import { sleep } from '../../utils/helpers'

import { ROLES, useUserState } from './constants'
import { generateColumns } from './columns'
import './workspace.less'
import { useElaiNotification } from '../../hooks/useElaiNotification'

const Workspace = () => {
  const notification = useElaiNotification()
  const { setHeader } = useStore((stores) => stores.domStore)
  const [form] = Form.useForm()
  const [editWorkspaceForm] = Form.useForm()

  const deleteAccountUserNameInput = useRef(null)
  const [users, setUsers] = useState()
  const [isDeletingAccount, setIsDeletingAccount] = useState(false)
  const [isSavingWorkspace, setIsSavingWorkspace] = useState(false)
  const [isShowEditButtons, setIsShowEditButtons] = useState(false)
  const [isOpenDeleteAccountModal, setIsOpenDeleteAccountModal] = useState(false)
  const [isUserAddModalVisible, setIsUserAddModalVisible] = useState(false)
  const [ssoEnabled, setSsoEnabled] = useState(false)

  const { authStore } = useUserState()
  const { user, refreshSession, logout, setSubAccountId } = authStore
  const { accountRole, account, canManageWorkspace, subAccountId, accounts } = user
  const accountId = subAccountId || account.id
  const isBasicPlanWithNoParentAccount = account.plan === 'basic' && !account.parentAccountId

  const fetchUsers = async () => {
    const users = await request({ method: 'get', url: '/users' })
    setUsers(users)
  }

  const fetchSSOConfig = async () => {
    const integration = await request({
      method: 'get',
      url: 'integration/sso',
    })
    setSsoEnabled(!!integration?._id)
  }

  const handleCancelEdit = () => {
    editWorkspaceForm.resetFields()
    setIsShowEditButtons(false)
  }

  const editWorkspace = async (data) => {
    if (accounts.find((u) => u?.name === data?.name)) {
      notification.warning({
        key: 'workspace-already-exist',
        message: `Workspace "${data.name}" already exist`,
        duration: 6,
      })
      return
    }
    setIsSavingWorkspace(true)
    const res = await request({ method: 'patch', url: `/accounts/${accountId}`, data })
    await refreshSession()
    setIsSavingWorkspace(false)
    if (res) {
      notification.success({
        message: 'Workspace successfully saved.',
        duration: 6,
      })
      setIsShowEditButtons(false)
    }
  }

  const deleteAccount = async () => {
    let data
    const input = deleteAccountUserNameInput?.current?.input
    if (input && (!input?.value || input?.value !== user.name))
      return notification.error({ message: 'Invalid user name. Type it correctly to confirm deletion.', duration: 4 })
    if (input?.value) data = { userName: input.value }
    setIsDeletingAccount(true)
    let isTimeout
    const controller = new AbortController()
    const signal = controller.signal
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    new Promise(() =>
      setTimeout(async () => {
        if (signal.aborted) return
        notification.success({
          message:
            'Seems like deletion is taking longer time than expected. You will be notified by email when everything is done. Now you will be redirected.',
          duration: 6,
        })
        isTimeout = true
        source.cancel()
        await sleep(2000)
        await redirectAfterDeletion()
      }, 59000),
    )
    const res = await request({ method: 'delete', url: `/accounts/${accountId}`, data })
    if (!isTimeout) {
      controller.abort()
      if (res === false) return setIsDeletingAccount(false)
      notification.success({
        message: 'Account was successfully removed with all data. You will be redirected soon.',
        duration: 4,
      })
      await redirectAfterDeletion()
    }
  }

  const redirectAfterDeletion = async () => {
    await sleep(4000)
    setIsOpenDeleteAccountModal(false)
    setIsDeletingAccount(false)
    if (account.parentAccountId) {
      setSubAccountId(account.parentAccountId)
      await refreshSession()
      window.location.reload()
    } else logout()
  }

  const addUser = async (data) => {
    const res = await request({ method: 'post', url: `/users`, data: data.user })
    if (res === false) return

    notification.success({
      message: res.message || 'User was successfully invited. They will receive an email with further instructions.',
      duration: 6,
    })

    fetchUsers()
    setIsUserAddModalVisible(false)
    form.resetFields()
  }

  const deleteUser = async (id) => {
    const res = await request({ method: 'delete', url: `/users/${id}` })
    if (res === false) return
    notification.success({ message: 'User was removed', duration: 4 })
    fetchUsers()
  }

  useEffect(() => {
    setHeader({
      title: 'Team',
      icon: <Icon name="team" />,
      link: '/workspace',
      extra:
        accountRole === 'admin' ? (
          <Tooltip
            placement="bottomLeft"
            overlayStyle={{ visibility: account.plan !== 'basic' ? 'hidden' : 'visible' }}
            title="Upgrade to Advanced or Enterprise plan to add your team mates"
          >
            <Button
              type="primary"
              icon={<Icon name="plus" />}
              disabled={account.plan === 'basic'}
              onClick={() => setIsUserAddModalVisible(true)}
            >
              Invite user
            </Button>
          </Tooltip>
        ) : null,
    })
    if (accountRole === 'admin') fetchSSOConfig()
    fetchUsers()
  }, [])
  const columns = generateColumns(user, deleteUser)

  return (
    <>
      <Layout.Content className="users-content">
        <h2>Workspace settings</h2>
        {!canManageWorkspace || isBasicPlanWithNoParentAccount ? (
          <p>
            {account?.name}
            <br />
            <br />
          </p>
        ) : (
          <>
            <Form
              layout="inline"
              requiredMark={false}
              form={editWorkspaceForm}
              onFinish={editWorkspace}
              initialValues={{ name: account?.name }}
            >
              <Form.Item name="name" style={{ width: 320 }}>
                <Input
                  placeholder="Workspace name"
                  onChange={(e) => setIsShowEditButtons(account?.name !== e.target.value)}
                />
              </Form.Item>
              {isShowEditButtons && (
                <>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" size="medium" loading={isSavingWorkspace}>
                      Save
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button type="default" onClick={handleCancelEdit}>
                      Cancel
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form>
            <br />
            <br />
          </>
        )}
        <h2>Users</h2>
        <Table
          sticky
          scroll={{ x: 900 }}
          dataSource={users}
          columns={columns}
          rowKey="_id"
          pagination={false}
          style={{ margin: '0 auto' }}
          className="users-table"
        />
        {canManageWorkspace && (
          <div className="delete-account">
            <div>
              <h2>Delete workspace</h2>
              <Typography.Text type="secondary">
                Delete this team and all resources
                <br /> associated with it permanently.
              </Typography.Text>
            </div>
            <Tooltip
              title={
                user.accounts.length > 1 && !account.parentAccountId
                  ? "You can't delete this workspace while it has dependent workspaces. Please delete them first."
                  : ''
              }
            >
              <Button
                type="primary"
                danger
                disabled={user.accounts.length > 1 && !account.parentAccountId}
                className="btn-delete-account"
                onClick={() => setIsOpenDeleteAccountModal(true)}
              >
                Delete workspace
              </Button>
            </Tooltip>
          </div>
        )}
      </Layout.Content>
      <Modal
        title="Invite User"
        open={isUserAddModalVisible}
        width={400}
        footer={null}
        onCancel={() => {
          setIsUserAddModalVisible(false)
          form.resetFields()
        }}
      >
        <Form form={form} name="nest-messages" onFinish={addUser} layout="vertical" requiredMark={false}>
          <Form.Item
            name={['user', 'name']}
            label={'Name' + (ssoEnabled ? ' (optional)' : '')}
            validateTrigger="onBlur"
            rules={[
              {
                required: !ssoEnabled,
                message: 'Please enter user name',
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Full name" />
          </Form.Item>
          <Form.Item
            name={['user', 'email']}
            label="Email"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please enter user email',
              },
            ]}
          >
            <Input prefix={<MailOutlined />} placeholder="user@company.com" />
          </Form.Item>
          <Form.Item
            name={['user', 'role']}
            label="Role"
            initialValue="user"
            validateTrigger="onBlur"
            rules={[{ required: true, message: 'Role missing' }]}
          >
            <Select>
              {ROLES.map((item) => (
                <Select.Option key={item.role} value={item.role}>
                  {item.role}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Invite
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        centered
        open={isOpenDeleteAccountModal}
        width={400}
        className="delete-account-modal"
        confirmLoading={isDeletingAccount}
        okButtonProps={{ danger: true }}
        okText="Delete workspace"
        onOk={deleteAccount}
        onCancel={() => setIsOpenDeleteAccountModal(false)}
      >
        <h2>Delete your workspace?</h2>
        <p>
          To protect your data privacy, we support your decision of workspace deletion at anytime. Once your workspace
          is deleted, you will NOT:
        </p>
        <ul>
          <li>have access to any of your previous videos;</li>
          <li>have access to any uploaded media;</li>
          <li>get refund for your left credits;</li>
          <li>migrate your Custom Avatar to other accounts.</li>
        </ul>
        {user.accounts.length === 1 && (
          <>
            <p>
              As this is your last workspace please also confirm to delete user data by typing your user name <br />
              <b>{user.name}</b> in the field below:
            </p>
            <Input placeholder="Type your name here..." ref={deleteAccountUserNameInput} />
            <br />
            <br />
          </>
        )}
        <p>
          This action is <b>irrevocable</b>. Please confirm it.
        </p>
      </Modal>
    </>
  )
}

export default Workspace
