import { Link } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { Row, Col, Modal, Radio } from 'antd'

import Icon from '../../../../components/Icon'
import { TemplatesColumn } from './templatesColumn'
import PageHeader from '../../../../components/PageHeader/pageHeader'

import { useStore } from '../../../../store'
import { request } from '../../../../utils/api'

import { style } from './constants'

export const TemplatesModal = (props) => {
  const {
    video,
    updateVideo,
    setIsSwitching,
    generateModalFooter,
    isOpenTemplatesModal,
    setIsOpenTemplatesModal,
    validateAndGenerateVideo,
    updateCanvasesInStoryMode,
    setIsEditorBusy,
  } = props

  const storeTemplates = useStore((stores) => stores.videosStore.templates)
  const [templatesFilter, setTemplatesFilter] = useState()
  const [templates, setTemplates] = useState()
  const [openedTemplate, setOpenedTemplate] = useState(false)
  const [isChangingTemplate, setIsChangingTemplate] = useState(false)

  const ttvTemplates = useMemo(
    () => storeTemplates?.filter((v) => v.template.enabled && !v.template.blank),
    [storeTemplates],
  )

  const containsPrivateTemplates = ttvTemplates?.some((t) => t.template.private)

  const changeTemplate = async (createSlides = false) => {
    setIsChangingTemplate(true)
    setIsEditorBusy(true)

    const updatedVideo = await request({
      method: 'POST',
      url: `story/change-template/${video._id}`,
      data: { templateId: openedTemplate._id },
    })
    if (!updatedVideo) return setIsChangingTemplate(false)

    updateVideo(updatedVideo, { skipAutoSave: true })
    updateCanvasesInStoryMode(updatedVideo.slides)

    setIsChangingTemplate(false)
    setIsEditorBusy(false)
    setIsOpenTemplatesModal(false)
    setOpenedTemplate(false)
    setIsSwitching(false)
    if (createSlides === true) {
      // wait while video state will be completely updated
      setTimeout(() => validateAndGenerateVideo(), 1000)
    }
  }

  useEffect(() => {
    setTemplatesFilter(containsPrivateTemplates ? 'private' : 'all')
    setTemplates(
      containsPrivateTemplates ? ttvTemplates.filter((t) => t.template.private && t.template.enabled) : ttvTemplates,
    )
  }, [storeTemplates])

  const onChangeTemplatesFilter = (e) => {
    const filter = e.target.value
    setTemplatesFilter(filter)
    setTemplates(() => {
      if (filter === 'all') return ttvTemplates
      else if (filter === 'private') return ttvTemplates.filter((t) => t.template.private && t.template.enabled)
      else if (filter === '16_9') return ttvTemplates.filter((t) => !t.data?.format || t.data.format === '16_9')
      else if (filter.format !== 'all') return ttvTemplates.filter((t) => t.data?.format === filter)
    })
  }

  const onCancel = () => {
    setIsSwitching(false)
    setIsOpenTemplatesModal(false)
    setOpenedTemplate(false)
  }

  const modalFooter = generateModalFooter(changeTemplate, openedTemplate)
  const onBack = openedTemplate ? () => setOpenedTemplate(null) : false

  const handleCreateTemplate = () => localStorage.setItem('isNewTemplateModalOpen', 'open')

  return (
    <Modal
      centered
      open={isOpenTemplatesModal}
      width={700}
      destroyOnClose
      className="templates-modal"
      cancelButtonProps={style.cancelButtonProps}
      confirmLoading={isChangingTemplate}
      footer={modalFooter}
      onCancel={onCancel}
    >
      <PageHeader style={style.pageHeader} title="Choose a template" onBack={onBack} />
      {openedTemplate ? (
        <>
          <Scrollbars className="template-scrollbar scrollbar" style={style.height319}>
            <Row gutter={[16, 16]}>
              {openedTemplate.slides.map((s) => (
                <Col
                  xs={openedTemplate?.data?.format === '9_16' ? 6 : openedTemplate?.data?.format === '1_1' ? 6 : 12}
                  sm={openedTemplate?.data?.format === '9_16' ? 4 : openedTemplate?.data?.format === '1_1' ? 6 : 12}
                  md={openedTemplate?.data?.format === '9_16' ? 3 : openedTemplate?.data?.format === '1_1' ? 6 : 8}
                  lg={openedTemplate?.data?.format === '9_16' ? 3 : openedTemplate?.data?.format === '1_1' ? 6 : 8}
                  key={s.id}
                >
                  <img
                    src={s.screenshot}
                    className={`template-media ${
                      openedTemplate?.data?.format === '9_16' || openedTemplate?.data?.format === '1_1'
                        ? 'vertical'
                        : ''
                    }`}
                  />
                </Col>
              ))}
            </Row>
          </Scrollbars>
        </>
      ) : (
        <>
          <Radio.Group className="format-radio-btns" value={templatesFilter} onChange={onChangeTemplatesFilter}>
            <Radio.Button value="all" className={`format-radio-btn ${templatesFilter === 'all' ? 'active' : ''}`}>
              <Icon name="page_collection" />
              <span>All</span>
            </Radio.Button>
            {containsPrivateTemplates && (
              <Radio.Button
                value="private"
                className={`format-radio-btn ${templatesFilter === 'private' ? 'active' : ''}`}
              >
                <Icon name="multiple_image" />
                <span>My templates</span>
              </Radio.Button>
            )}
            <Radio.Button value="16_9" className={`format-radio-btn ${templatesFilter === '16_9' ? 'active' : ''}`}>
              <Icon name="monitor" />
              <span>Horizontal</span>
            </Radio.Button>
            <Radio.Button value="9_16" className={`format-radio-btn ${templatesFilter === '9_16' ? 'active' : ''}`}>
              <Icon name="mobile" />
              <span>Vertical</span>
            </Radio.Button>
          </Radio.Group>
          <Scrollbars className="template-scrollbar scrollbar" style={{ height: containsPrivateTemplates ? 267 : 319 }}>
            <Row gutter={[16, 16]}>
              <Col key="create-template" style={style.flex} xs={12} sm={12} md={8}>
                <Link to="/templates" target="_blank" style={style.link} onClick={handleCreateTemplate}>
                  <div className="create-template-option">
                    <Icon name="add_template" />
                    <span>Create {templatesFilter === 'private' ? 'new' : 'own'} template</span>
                  </div>
                </Link>
              </Col>
              {templates?.map((v, index) => (
                <TemplatesColumn key={v._id} video={v} index={index} setOpenedTemplate={setOpenedTemplate} />
              ))}
            </Row>
          </Scrollbars>
        </>
      )}
    </Modal>
  )
}
