import { Layout, Button } from 'antd'

import { CoursesTable } from './components'
import PageHeader from '../../../components/PageHeader/pageHeader'

import { useCoursesState } from './useCoursesState'

import { academyIcons, zeroPadding } from './constants'
import { CreateCourseModal } from './components/createCourseModal'

import './adminAcademy.less'

const AdminAcademy = () => {
  const {
    courses,
    courseForm,
    userVideos,
    setCourses,
    editCourse,
    isModalOpen,
    deleteCourse,
    editableCourse,
    setIsModalOpen,
    setEditableCourse,
    navigateToLessons,
    handleCreateCourse,
  } = useCoursesState()

  const pageHeaderExtra = [
    <Button type="primary" key="create-video-btn" onClick={handleCreateCourse} icon={academyIcons.plusOutlined}>
      New Course
    </Button>,
  ]

  return (
    <Layout.Content className="admin-content">
      <div className="content">
        <PageHeader title="Courses" style={zeroPadding} extra={pageHeaderExtra} />
        <CoursesTable
          courses={courses}
          deleteCourse={deleteCourse}
          editCourse={editCourse}
          navigateToLessons={navigateToLessons}
        />
      </div>
      <CreateCourseModal
        courseForm={courseForm}
        userVideos={userVideos}
        setCourses={setCourses}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        editableCourse={editableCourse}
        setEditableCourse={setEditableCourse}
      />
    </Layout.Content>
  )
}

export default AdminAcademy
