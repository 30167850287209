import { fabric } from 'fabric'
import { uniqueId } from '../helpers'
import { checkboxes, interactiveQuestionsData, questionThemes } from '../canvas/interactiveObjects'

const SCROLLABLE_CONTAINER_PATTERN_SCALE = 4
const TRANSFORM_PATTERN_COEFF = (100 / SCROLLABLE_CONTAINER_PATTERN_SCALE) * 0.01
const QUESTION_PROPS_TO_INCLUDE = ['question', 'interactivity']
const ANSWERS_SCROLL_SPEED = 5
const LAYOUT_GAP = 26
const VERTICAL_LAYOUT_ANSWERS_GAP = 8.5
const VERTICAL_LAYOUT_ANSWER_TEXT_WIDTH = 266.4
const VERTICAL_LAYOUT_QUESTION_TEXT_WIDTH = 318.2
const VERTICAL_LAYOUT_PADDING_TOP = 28
const VERTICAL_LAYOUT_PADDING_BOTTOM = 53
const MAX_QUESTION_HEIGHT = 332
const HORIZONTAL_LAYOUT_ANSWER_TEXT_WIDTH = 115.9
const HORIZONTAL_LAYOUT_QUESTION_TEXT_WIDTH = 411.7
const HORIZONTAL_LAYOUT_PADDING_SMALL = 46
const HORIZONTAL_LAYOUT_PADDING_LARGE = 68
const HORIZONTAL_LAYOUT_ANSWERS_GAP = 11
const HORIZONTAL_LAYOUT_ROW_LENGTH = 3
const HORIZONTAL_LAYOUT_ANSWER_PADDING = 14.8
const HORIZONTAL_LAYOUT_ANSWER_WIDTH = 163
const SEND_BUTTON_MARGIN = 12
const SEND_BUTTON_MARGIN_SMALL = 10
const CHECKBOX_TYPE = 'checkbox'
const RADIO_TYPE = 'radio'

const Question = fabric.util.createClass(fabric.Group, {
  type: 'question',
  lockRotation: true,
  lockSkewingX: true,
  lockSkewingY: true,
  lockScalingFlip: true,

  initialize: function (objects, options, isAlreadyGrouped) {
    options = options || {}
    const answersContainer = objects.find((obj) => obj.question.element === 'answers_container')
    const answersGroup = objects.find((obj) => obj.question.element === 'answers_group')
    this.patternSourceCanvas = new fabric.StaticCanvas(null, { enableRetinaScaling: false })
    this.patternSourceCanvas.add(answersGroup)
    answersGroup.set({ top: 0, left: 0 })
    answersGroup.scale(SCROLLABLE_CONTAINER_PATTERN_SCALE)
    this.patternSourceCanvas.setDimensions({
      width: answersGroup.getScaledWidth(),
      height: answersGroup.getScaledHeight(),
    })
    this.patternSourceCanvas.renderAll()
    const patternOptions = {
      source: this.patternSourceCanvas.getElement(),
      repeat: 'no-repeat',
      offsetX: 0,
      offsetY: 0,
      patternTransform: [TRANSFORM_PATTERN_COEFF, 0, 0, TRANSFORM_PATTERN_COEFF, 0, 0],
    }
    answersContainer.fill = new fabric.Pattern(patternOptions)
    const filteredObjects = objects.filter((obj) => obj.question.element !== 'answers_group')
    this.callSuper('initialize', filteredObjects, options, isAlreadyGrouped)
    this._drawAnswersScrollTrack()
    this.on({
      mousewheel: (options) => {
        const answersContainer = this.getObjects().find((obj) => obj.question.element === 'answers_container')
        const answersGroup = this.patternSourceCanvas.getObjects()[0]
        const answerGroupHeight = answersGroup.height + answersGroup.strokeWidth
        this._moveAnswersScrollableContent(answersContainer, answerGroupHeight, options.e.deltaY)
        this._moveAnswersScrollThumb(answersContainer, answerGroupHeight)
        this.dirty = true
        this.canvas.renderAll()
      },
    })
    this.setControlsVisibility({ mt: false, ml: false, mr: false, mb: false, mtr: false })
  },

  getQuestionText: function () {
    return this.getObjects().find((obj) => obj.question.element === 'question')?.text
  },

  getAnswers: function () {
    const answersGroup = this.patternSourceCanvas.getObjects()[0]
    return answersGroup.getObjects().map((obj) => {
      const text = obj.getObjects().find((obj) => obj.question.element === 'answer_text').text
      return { id: obj.question.answerId, text, interactivity: obj.interactivity }
    })
  },

  getAnswersType: function () {
    const answersGroup = this.patternSourceCanvas.getObjects()[0]
    const firstAnswerCheckbox = answersGroup
      .getObjects()[0]
      .getObjects()
      .find((obj) => obj.question.element === 'answer_checkbox')
    return firstAnswerCheckbox.question.type || 'checkbox'
  },

  _moveAnswersScrollableContent: function (answersContainer, answerGroupHeight, delta) {
    if (answersContainer.height >= answerGroupHeight) return
    let offsetY = answersContainer.fill.offsetY - delta / ANSWERS_SCROLL_SPEED
    if (offsetY > 0) {
      offsetY = 0
    } else if (Math.abs(offsetY) + answersContainer.height > answerGroupHeight) {
      offsetY = -(answerGroupHeight - answersContainer.height)
    }
    answersContainer.fill.offsetY = offsetY
  },

  _moveAnswersScrollThumb: function (answersContainer, answerGroupHeight) {
    const scrollTrackGroup = this.getObjects().find((obj) => obj.question.element === 'answers_scroll')
    const scrollTrack = scrollTrackGroup.getObjects()[0]
    const scrollThumb = scrollTrackGroup.getObjects()[1]
    const thumbHeightDiff = scrollTrack.height - scrollThumb.height
    const thumbTopInitial = scrollTrack.top - thumbHeightDiff
    const thumbTopPercent = (100 * Math.abs(answersContainer.fill.offsetY)) / answerGroupHeight
    const thumbTopScrolled = (scrollTrack.height * thumbTopPercent) / 100
    const thumbTop = thumbTopInitial + thumbTopScrolled
    scrollThumb.set({ top: thumbTop })
  },

  _drawAnswersScrollTrack: function () {
    const answersContainer = this.getObjects().find((obj) => obj.question.element === 'answers_container')
    const answersGroup = this.patternSourceCanvas.getObjects()[0]
    const answerGroupHeight = answersGroup.height + answersGroup.strokeWidth
    const scrollTrackGroup = this.getObjects().find((obj) => obj.question.element === 'answers_scroll')
    if (Math.abs(answersContainer.fill.offsetY) + answersContainer.height > answerGroupHeight) {
      answersContainer.fill.offsetY = -(answerGroupHeight - answersContainer.height)
    }
    if (answersContainer.height >= answerGroupHeight) {
      scrollTrackGroup.visible = false
      return
    } else {
      scrollTrackGroup.visible = true
    }
    const scrollTrack = scrollTrackGroup.getObjects()[0]
    const scrollThumb = scrollTrackGroup.getObjects()[1]
    const scrollTrackGroupHeight = scrollTrackGroup.height
    scrollTrackGroup.height = answersContainer.height
    scrollTrackGroup.top = answersContainer.top
    scrollTrack.height = answersContainer.height
    const scrollTrackGroupHeightDiff = scrollTrackGroupHeight - scrollTrackGroup.height
    scrollTrackGroup.forEachObject((obj) => {
      const objTop = obj.top - scrollTrackGroupHeightDiff / 2
      obj.set('top', objTop)
    })
    const thumbHeightPercent = (100 * answersContainer.height) / answerGroupHeight
    const thumbHeight = (scrollTrack.height * thumbHeightPercent) / 100
    scrollThumb.set({ height: thumbHeight })
    this._moveAnswersScrollThumb(answersContainer, answerGroupHeight)
  },

  _updateAnswerObjHeight: function (answerObj, newHeight) {
    const answerObjHeightDiff = newHeight - answerObj.height
    const answerBackgroundObj = answerObj.getObjects().find((obj) => obj.question.element === 'answer_background')
    answerObj.set({ height: newHeight })
    answerBackgroundObj.set({ height: newHeight - answerBackgroundObj.strokeWidth / 2 })
    answerObj.forEachObject((obj) => {
      const objTop = obj.top - answerObjHeightDiff / 2
      obj.set('top', objTop)
    })
  },

  _drawSendButton: function () {
    const answersGroup = this.patternSourceCanvas.getObjects()[0]
    const sendButton = this.getObjects().find((obj) => obj.question.element === 'button_send')
    let padding
    if (this.layoutType === 'horizontal' && answersGroup.getObjects().length > HORIZONTAL_LAYOUT_ROW_LENGTH) {
      sendButton.set({ scaleX: 0.8, scaleY: 0.8 })
      padding = SEND_BUTTON_MARGIN_SMALL
    } else {
      sendButton.set({ scaleX: 1, scaleY: 1 })
      padding = SEND_BUTTON_MARGIN
    }
    const top = this.height / 2 - sendButton.height * sendButton.scaleY - padding
    const left = this.width / 2 - sendButton.width * sendButton.scaleX - padding
    sendButton.set({ top, left })
  },

  _setQuestionText: function (value) {
    if (this.layoutType === 'vertical') this._setVerticalLayoutQuestionText(value)
    else if (this.layoutType === 'horizontal') this._setHorizontalLayoutQuestionText(value)
    this._regulateQuestionHeight()
    this._drawAnswersScrollTrack()
    this._drawSendButton()
  },

  _setVerticalLayoutQuestionText: function (value) {
    const questionTextObj = this.getObjects().find((obj) => obj.question.element === 'question')
    const questionTextHeight = questionTextObj.height
    const formattedText = this._wrapText(questionTextObj, value, VERTICAL_LAYOUT_QUESTION_TEXT_WIDTH)
    questionTextObj.set('text', formattedText)
    const questionTextHeightDiff = questionTextHeight - questionTextObj.height
    const answersContainer = this.getObjects().find((obj) => obj.question.element === 'answers_container')
    const answersContainerTop = answersContainer.top - questionTextHeightDiff
    const answersContainerHeight = answersContainer.height + questionTextHeightDiff
    answersContainer.set({ top: answersContainerTop, height: answersContainerHeight })
  },

  _setHorizontalLayoutQuestionText: function (value) {
    const questionTextObj = this.getObjects().find((obj) => obj.question.element === 'question')
    const answersContainer = this.getObjects().find((obj) => obj.question.element === 'answers_container')
    const formattedText = this._wrapText(questionTextObj, value, HORIZONTAL_LAYOUT_QUESTION_TEXT_WIDTH)
    questionTextObj.set('text', formattedText)
    const answersContainerUpdatedTop = questionTextObj.top + questionTextObj.height + LAYOUT_GAP
    answersContainer.set('top', answersContainerUpdatedTop)
  },

  _setAnswerText: function ({ id, text }) {
    if (this.layoutType === 'vertical') this._setAnswerTextForVerticalLayout({ id, text })
    else if (this.layoutType === 'horizontal') this._setAnswerTextForHorizontalLayout({ id, text })
    this._regulateQuestionHeight()
    this._drawAnswersScrollTrack()
    this._drawSendButton()
  },

  _setAnswerTextForVerticalLayout: function ({ id, text }) {
    const answersGroup = this.patternSourceCanvas.getObjects()[0]
    const answersGroupObjects = answersGroup.getObjects()
    const answerObj = answersGroupObjects.find((obj) => obj.question.answerId === id)
    const answerTextObj = answerObj.getObjects().find((obj) => obj.question.element === 'answer_text')
    const answerTextObjHeight = answerTextObj.height
    const formattedText = this._wrapText(answerTextObj, text, VERTICAL_LAYOUT_ANSWER_TEXT_WIDTH)
    answerTextObj.set('text', formattedText)
    const answerTextObjHeightDiff = answerTextObjHeight - answerTextObj.height
    const answerRectObjHeight = answerObj.height - answerTextObjHeightDiff
    this._updateAnswerObjHeight(answerObj, answerRectObjHeight)
    this._updateAnswerGroupAfterTextChanged(answersGroup, answerObj.top, answerTextObjHeightDiff)
    this._drawAnswers()
    this.dirty = true
  },

  _setAnswerTextForHorizontalLayout: function ({ id, text }) {
    const answersGroup = this.patternSourceCanvas.getObjects()[0]
    const answersGroupObjects = answersGroup.getObjects()
    const answerObj = answersGroupObjects.find((obj) => obj.question.answerId === id)
    const answerTextObj = answerObj.getObjects().find((obj) => obj.question.element === 'answer_text')
    const rowMaxTextHeight = this._getMaxTextHeightOfRow(answersGroupObjects, answerObj.top)
    const formattedText = this._wrapText(answerTextObj, text, HORIZONTAL_LAYOUT_ANSWER_TEXT_WIDTH)
    answerTextObj.set('text', formattedText)
    const rowMaxTextUpdatedHeight = this._getMaxTextHeightOfRow(answersGroupObjects, answerObj.top)
    const answerTextObjHeightDiff = rowMaxTextHeight - rowMaxTextUpdatedHeight
    const answerRectObjHeight = answerObj.height - answerTextObjHeightDiff
    if (rowMaxTextHeight !== rowMaxTextUpdatedHeight) {
      answersGroupObjects.forEach((obj) => {
        if (obj.top === answerObj.top) this._updateAnswerObjHeight(obj, answerRectObjHeight)
      })
      this._updateAnswerGroupAfterTextChanged(answersGroup, answerObj.top, answerTextObjHeightDiff)
    }
    this._drawAnswers()
    this.dirty = true
  },

  _wrapText: function (textObj, text, maxWidth) {
    const words = text.split(/[ \n]/)
    const context = this.canvas.getContext('2d')
    context.font = `${textObj.fontWeight || 'normal'} ${textObj.fontSize}px ${textObj.fontFamily}`
    let formatted = ''
    let currentLine = ''
    for (let n = 0; n < words.length; n++) {
      let isNewLine = currentLine === ''
      let testOverlap = currentLine + ' ' + words[n]
      const textWidth = context.measureText(testOverlap).width
      if (textWidth < maxWidth) {
        const addToCurrentLine = words[n] ? words[n] + ' ' : words[n]
        const addToFormatted = words[n] ? (words[n] += ' ') : words[n]
        currentLine += addToCurrentLine
        formatted += addToFormatted
      } else {
        if (isNewLine) {
          let wordOverlap = ''
          for (let i = 0; i < words[n].length; ++i) {
            wordOverlap += words[n].charAt(i)
            if (context.measureText(wordOverlap).width >= maxWidth) {
              wordOverlap = wordOverlap.slice(0, wordOverlap.length - 2)
              words[n] = words[n].slice(wordOverlap.length - 1, words[n].length)
              formatted += wordOverlap
              break
            }
          }
        }
        n--
        formatted += '\n'
        currentLine = ''
      }
    }
    formatted = formatted.slice(0, formatted.length - 1)
    return formatted
  },

  _updateAnswerGroupAfterTextChanged: function (answersGroup, answerObjTop, answerTextObjHeightDiff) {
    const answersGroupObjects = answersGroup.getObjects()
    const answersGroupHeight = answersGroup.height - answerTextObjHeightDiff
    const answersGroupHeightDiff = answersGroupHeight - answersGroup.height
    answersGroup.set({ height: answersGroupHeight })
    answersGroupObjects.forEach((obj) => {
      let objTop
      if (obj.top > answerObjTop) objTop = obj.top - answersGroupHeightDiff / 2 - answerTextObjHeightDiff
      else objTop = obj.top - answersGroupHeightDiff / 2
      obj.set('top', objTop)
    })
  },

  _getMaxTextHeightOfRow: function (objects, topPos) {
    const rowTextHeights = []
    objects.forEach((obj) => {
      if (topPos === obj.top) {
        const textHeight = obj.getObjects().find((obj) => obj.question.element === 'answer_text').height
        rowTextHeights.push(textHeight)
      }
    })
    return Math.max(...rowTextHeights)
  },

  _markCorrectAnswer: function (id) {
    if (this.getAnswersType() === 'radio') this._markRadioAnswer(id)
    else this._markCheckboxAnswer(id)
  },

  _markCheckboxAnswer: function (id) {
    if (this.correctAnswers.includes(id)) return
    this.correctAnswers.push(id)
    this._changeCorrectAnswers(id, true, CHECKBOX_TYPE)
  },

  _markRadioAnswer: function (id) {
    const markedAnswer = this.correctAnswers[0]
    if (markedAnswer === id) return
    this._changeCorrectAnswers(markedAnswer, false, RADIO_TYPE)
    this._changeCorrectAnswers(id, true, RADIO_TYPE)
    this.correctAnswers = [id]
  },

  _unmarkCorrectAnswer: function (id) {
    this.correctAnswers = this.correctAnswers.filter((answer) => answer !== id)
    this._changeCorrectAnswers(id, false, CHECKBOX_TYPE)
  },

  _changeCorrectAnswers: function (id, markAsCorrect, answersType) {
    if (!id) return
    const answerIndex = markAsCorrect ? 0 : 1
    const answersGroup = this.patternSourceCanvas.getObjects()[0]
    const answersObjects = answersGroup.getObjects()
    const answerObj = answersObjects.find((obj) => obj.question.answerId === id)
    const answerCheckboxObj = answerObj.getObjects().find((obj) => obj.question.element === 'answer_checkbox')
    answerObj.remove(answerCheckboxObj)
    const radioObj = interactiveQuestionsData.vertical.objects
      .find((obj) => obj.question.element === 'answers_group')
      .objects[answerIndex].objects.find((obj) => obj.question.element === 'answer_checkbox')
    const checkboxObj = checkboxes[markAsCorrect ? 'checked' : 'unchecked']
    const serializedObj = answersType === 'radio' ? radioObj : checkboxObj
    fabric.util.enlivenObjects([serializedObj], ([enlivenObj]) => {
      enlivenObj.set({ top: answerCheckboxObj.top, left: answerCheckboxObj.left })
      answerObj.add(enlivenObj)
      answersGroup.dirty = true
      this.dirty = true
      this._drawAnswers()
    })
  },

  _setAnswerInteractivity: function ({ id, interactivity }) {
    const answersGroup = this.patternSourceCanvas.getObjects()[0]
    const answerObj = answersGroup.getObjects().find((obj) => obj.question.answerId === id)
    answerObj.set({ interactivity })
    answerObj.dirty = true
    answersGroup.dirty = true
    this.dirty = true
    this._drawAnswers()
  },

  _addAnswer: function () {
    if (this.layoutType === 'vertical') this._addAnswerToVerticalLayout()
    else if (this.layoutType === 'horizontal') this._addAnswerToHorizontalLayout()
    this._regulateQuestionHeight()
    this._drawAnswersScrollTrack()
    this._drawSendButton()
  },

  _getSerializedAnswerObject: function () {
    const answersType = this.getAnswersType()
    const answerObject = interactiveQuestionsData[this.layoutType].objects.find(
      (obj) => obj.question.element === 'answers_group',
    ).objects[1]
    if (answersType === RADIO_TYPE) {
      return answerObject
    } else {
      const { top, left } = answerObject.objects.find((obj) => obj.question.element === 'answer_checkbox')
      const objects = answerObject.objects.filter((obj) => obj.question.element !== 'answer_checkbox')
      const newCheckbox = { ...checkboxes.unchecked, left, top }
      objects.push(newCheckbox)
      return { ...answerObject, objects }
    }
  },

  _addAnswerToVerticalLayout: function () {
    const answersGroup = this.patternSourceCanvas.getObjects()[0]
    const serializedAnswerObject = this._getSerializedAnswerObject()
    const serializedTextObj = serializedAnswerObject.objects.find((obj) => obj.question.element === 'answer_text')
    serializedTextObj.fill = questionThemes[this.theme].textColor
    fabric.util.enlivenObjects([serializedAnswerObject], ([answerObj]) => {
      answerObj.set({ question: { ...answerObj.question, answerId: uniqueId() } })
      answersGroup.add(answerObj)
      const answersGroupObjects = answersGroup.getObjects()
      const answersGroupHeightDiff = answerObj.height + VERTICAL_LAYOUT_ANSWERS_GAP
      const answersGroupUpdatedHeight = answersGroup.height + answersGroupHeightDiff
      answersGroup.set({ height: answersGroupUpdatedHeight })
      answersGroupObjects.forEach((obj, index) => {
        let objTop
        if (answerObj.question.answerId === obj.question.answerId) {
          const previousAnswer = answersGroupObjects[index - 1]
          objTop = previousAnswer.top + previousAnswer.height + VERTICAL_LAYOUT_ANSWERS_GAP
        } else {
          objTop = obj.top - answersGroupHeightDiff / 2
        }
        obj.set('top', objTop)
      })
      this.dirty = true
      this._drawAnswers()
    })
  },

  _addAnswerToHorizontalLayout: function () {
    const answersGroup = this.patternSourceCanvas.getObjects()[0]
    const answersGroupObjects = answersGroup.getObjects()
    const serializedAnswerObject = this._getSerializedAnswerObject()
    const serializedTextObj = serializedAnswerObject.objects.find((obj) => obj.question.element === 'answer_text')
    serializedTextObj.fill = questionThemes[this.theme].textColor
    fabric.util.enlivenObjects([serializedAnswerObject], ([answerObj]) => {
      const topPositions = answersGroupObjects.map((obj) => obj.top)
      const incompleteRowTopPosition = topPositions.find((position) => {
        const answersRow = topPositions.filter((p) => p === position)
        const isFullRow = answersRow.length === HORIZONTAL_LAYOUT_ROW_LENGTH
        return !isFullRow
      })
      if (incompleteRowTopPosition) this._addAnswerToExistingRow(incompleteRowTopPosition, answerObj)
      else this._addAnswerToNewRow(topPositions, answerObj)
    })
  },

  _addAnswerToNewRow: function (topPositions, answerObj) {
    const answersGroup = this.patternSourceCanvas.getObjects()[0]
    const answersGroupObjects = answersGroup.getObjects()
    const maxTopPosition = Math.max(...topPositions)
    const answerFromLastRow = answersGroupObjects.find((obj) => obj.top === maxTopPosition)
    const newRowTopPosition = maxTopPosition + answerFromLastRow.height + HORIZONTAL_LAYOUT_ANSWERS_GAP
    const newRowLeftPosition = -answerObj.width / 2
    answerObj.set({
      top: newRowTopPosition,
      left: newRowLeftPosition,
      question: { ...answerObj.question, answerId: uniqueId() },
    })
    answersGroup.add(answerObj)
    const updatedAnswersGroupObjects = answersGroup.getObjects()
    const answersGroupHeightDiff = answerObj.height + HORIZONTAL_LAYOUT_ANSWERS_GAP
    const answersGroupUpdatedHeight = answersGroup.height + answersGroupHeightDiff
    answersGroup.set({ height: answersGroupUpdatedHeight })
    updatedAnswersGroupObjects.forEach((obj) => {
      const objTop = obj.top - answersGroupHeightDiff / 2
      obj.set('top', objTop)
    })
    this.dirty = true
    this._drawAnswers()
  },

  _addAnswerToExistingRow: function (incompleteRowTopPosition, answerObj) {
    const answersGroup = this.patternSourceCanvas.getObjects()[0]
    const answersGroupObjects = answersGroup.getObjects()
    const answersRow = answersGroupObjects.filter((answer) => answer.top === incompleteRowTopPosition)
    answerObj.set({
      top: incompleteRowTopPosition,
      question: { ...answerObj.question, answerId: uniqueId() },
    })
    this._updateAnswerObjHeight(answerObj, answersRow[0].height)
    answersRow.push(answerObj)
    this._alignRowToCenter(answersRow)
    answersGroup.add(answerObj)
    this.dirty = true
    this._drawAnswers()
  },

  _regulateQuestionHeight: function () {
    const answersGroup = this.patternSourceCanvas.getObjects()[0]
    const questionTextObj = this.getObjects().find((obj) => obj.question.element === 'question')
    const answersContainer = this.getObjects().find((obj) => obj.question.element === 'answers_container')
    let contentHeightToUpdate

    if (this.layoutType === 'vertical') {
      contentHeightToUpdate =
        VERTICAL_LAYOUT_PADDING_TOP +
        questionTextObj.height +
        LAYOUT_GAP +
        answersGroup.height +
        VERTICAL_LAYOUT_PADDING_BOTTOM
    } else {
      const padding =
        answersGroup.getObjects().length > HORIZONTAL_LAYOUT_ROW_LENGTH
          ? HORIZONTAL_LAYOUT_PADDING_SMALL
          : HORIZONTAL_LAYOUT_PADDING_LARGE
      const questionTextObjTop = -this.height / 2 + padding
      questionTextObj.set({ top: questionTextObjTop })
      const answersContainerTop = questionTextObjTop + questionTextObj.height + LAYOUT_GAP
      answersContainer.set({ top: answersContainerTop })
      contentHeightToUpdate = questionTextObj.height + LAYOUT_GAP + answersGroup.height + padding * 2
    }

    if (contentHeightToUpdate > MAX_QUESTION_HEIGHT) {
      this._updateQuestionGroupHeight(MAX_QUESTION_HEIGHT)
      const heightToUpdateContentDiff = contentHeightToUpdate - MAX_QUESTION_HEIGHT
      const answersContainerUpdatedHeight = answersGroup.height - heightToUpdateContentDiff
      answersContainer.set({ height: answersContainerUpdatedHeight })
    } else {
      this._updateQuestionGroupHeight(contentHeightToUpdate)
      answersContainer.set({ height: answersGroup.height })
    }
  },

  _updateQuestionGroupHeight: function (newHeight) {
    const groupHeightDiff = newHeight - this.height
    const groupTop = this.top - groupHeightDiff / 2
    this.set({ height: newHeight, top: groupTop })
    const backgroundObj = this.getObjects().find((obj) => obj.question.element === 'background')
    backgroundObj.set({ height: newHeight })
    this.forEachObject((obj) => {
      const top = obj.top - groupHeightDiff / 2
      obj.set({ top })
    })
  },

  _removeAnswer: function (id) {
    if (this.layoutType === 'vertical') this._removeAnswerFromVerticalLayout(id)
    else if (this.layoutType === 'horizontal') this._removeAnswerFromHorizontalLayout(id)
    const answersType = this.getAnswersType()
    if (answersType === 'radio') this._removeRadioAnswer(id)
    else this._removeCheckboxAnswer(id)
    this._regulateQuestionHeight()
    this._drawAnswersScrollTrack()
    this._drawSendButton()
  },

  _removeCheckboxAnswer: function (id) {
    this.correctAnswers = this.correctAnswers.filter((answer) => answer !== id)
  },

  _removeRadioAnswer: function (id) {
    if (id !== this.correctAnswers[0]) return
    const answersGroup = this.patternSourceCanvas.getObjects()[0]
    const answersGroupObjects = answersGroup.getObjects()
    const firstAnswerId = answersGroupObjects[0].question.answerId
    this._changeCorrectAnswers(firstAnswerId, true, RADIO_TYPE)
    this.correctAnswers = [firstAnswerId]
  },

  _removeAnswerFromVerticalLayout: function (id) {
    const answersGroup = this.patternSourceCanvas.getObjects()[0]
    const answersGroupObjects = answersGroup.getObjects()
    const answerIndex = answersGroupObjects.findIndex((obj) => obj.question.answerId === id)
    const answerObj = answersGroupObjects[answerIndex]
    const groupHeightDiff = answerObj.height + VERTICAL_LAYOUT_ANSWERS_GAP
    const answersGroupUpdatedHeight = answersGroup.height - groupHeightDiff
    answersGroup.set({ height: answersGroupUpdatedHeight })
    answersGroupObjects.forEach((obj, index) => {
      let objTop
      if (index > answerIndex) objTop = obj.top + groupHeightDiff / 2 - groupHeightDiff
      else objTop = obj.top + groupHeightDiff / 2
      obj.set('top', objTop)
    })
    answersGroup.remove(answerObj)
    this.dirty = true
    this._drawAnswers()
  },

  _removeAnswerFromHorizontalLayout: function (id) {
    const answersGroup = this.patternSourceCanvas.getObjects()[0]
    const answersGroupObjects = answersGroup.getObjects()
    const answerObjToDelete = answersGroupObjects.find((obj) => obj.question.answerId === id)
    const answerIndexToDelete = answersGroupObjects.findIndex((obj) => obj.question.answerId === id)
    const answersGroupObjectsProps = answersGroupObjects.map((obj) => {
      const objBackground = obj.getObjects().find((obj) => obj.question.element === 'answer_background')
      return { top: obj.top, left: obj.left, height: obj.height, backgroundHeight: objBackground.height }
    })
    this._changeModifiedRowAnswersHeight(answersGroupObjects, answerObjToDelete)
    answersGroupObjects.forEach((answerObj, index) => {
      if (index > answerIndexToDelete) {
        const previousAnswerProps = answersGroupObjectsProps[index - 1]
        const moveToAnotherRow = answerObj.top !== previousAnswerProps.top
        if (moveToAnotherRow) {
          this._changeMovedAnswerRowHeight(answersGroupObjects, answerObj)
          const answerTextObj = answerObj.getObjects().find((obj) => obj.question.element === 'answer_text')
          const answersRow = answersGroupObjects.filter(
            (obj) =>
              obj.question.answerId !== answerObjToDelete.question.answerId && obj.top === previousAnswerProps.top,
          )
          const maxRowTextHeight = this._getMaxTextHeightOfRow(answersRow, previousAnswerProps.top)
          if (maxRowTextHeight > answerTextObj.height) {
            this._increaseMovedAnswerObjHeight(answerObj, previousAnswerProps, answersRow, maxRowTextHeight)
          } else {
            this._increaseHeightOfModifiedRow(answerObj, answerTextObj, previousAnswerProps, answersRow)
          }
        } else {
          answerObj.set({ left: previousAnswerProps.left })
        }
      }
    })
    answersGroup.remove(answerObjToDelete)
    this._calculateRowsUpdatedTopPositions(answersGroupObjects, answerIndexToDelete, answerObjToDelete)
    this._alignLastRowToCenter(answersGroup)
    this._changeAnswersGroupHeight(answersGroup)
    this.dirty = true
    this._drawAnswers()
  },

  _changeModifiedRowAnswersHeight: function (answersGroupObjects, answerObjToDelete) {
    const answersObjFromRow = answersGroupObjects.filter((obj) => obj.top === answerObjToDelete.top)
    const answersObjFromUpdatedRow = answersObjFromRow.filter(
      (obj) => obj.question.answerId !== answerObjToDelete.question.answerId,
    )
    const maxUpdatedRowTextHeight = this._getMaxTextHeightOfRow(answersObjFromUpdatedRow, answerObjToDelete.top)
    const updatedRowHeight = maxUpdatedRowTextHeight + HORIZONTAL_LAYOUT_ANSWER_PADDING * 2
    answersObjFromUpdatedRow.forEach((obj) => {
      this._updateAnswerObjHeight(obj, updatedRowHeight)
    })
  },

  _changeMovedAnswerRowHeight: function (answersGroupObjects, answerObj) {
    const currentAnswerRow = answersGroupObjects.filter(
      (obj) => obj.question.answerId !== answerObj.question.answerId && obj.top === answerObj.top,
    )
    const maxCurrentAnswerRowTextHeight = this._getMaxTextHeightOfRow(currentAnswerRow, answerObj.top)
    const newRowHeight = maxCurrentAnswerRowTextHeight + HORIZONTAL_LAYOUT_ANSWER_PADDING * 2
    currentAnswerRow.forEach((obj) => this._updateAnswerObjHeight(obj, newRowHeight))
  },

  _increaseMovedAnswerObjHeight: function (answerObj, previousAnswerProps, answersRow, maxRowTextObjHeight) {
    const highestRowAnswer = answersRow.find((obj) => {
      const textObj = obj.getObjects().find((obj) => obj.question.element === 'answer_text')
      return textObj.height === maxRowTextObjHeight
    })
    answerObj.set({ top: previousAnswerProps.top, left: previousAnswerProps.left })
    this._updateAnswerObjHeight(answerObj, highestRowAnswer.height)
  },

  _increaseHeightOfModifiedRow: function (answerObj, answerTextObj, previousAnswerProps, answersRow) {
    const newRowHeight = answerTextObj.height + HORIZONTAL_LAYOUT_ANSWER_PADDING * 2
    answerObj.set({ top: previousAnswerProps.top, left: previousAnswerProps.left })
    answersRow.push(answerObj)
    answersRow.forEach((obj) => this._updateAnswerObjHeight(obj, newRowHeight))
  },

  _calculateRowsUpdatedTopPositions: function (answersGroupObjects, answerIndexToDelete, answerObjToDelete) {
    const rowsTopPositions = answersGroupObjects
      .map((obj, index) => (index >= answerIndexToDelete ? obj.top : null))
      .filter((value, index, self) => value && self.indexOf(value) === index)
    if (rowsTopPositions.length > 1) {
      const updatedRowTopPositions = []
      rowsTopPositions.forEach((pos, index) => {
        if (index === 0) return
        const positionsBefore = rowsTopPositions.slice(0, index)
        let topPosition = 0
        positionsBefore.forEach((position) => {
          const answerObj = answersGroupObjects.find((obj) => obj.top === position)
          topPosition += position + answerObj.height + HORIZONTAL_LAYOUT_ANSWERS_GAP
        })
        updatedRowTopPositions.push(topPosition)
      })
      rowsTopPositions.shift()
      answersGroupObjects.forEach((obj) => {
        if (obj.top > answerObjToDelete.top) {
          const topPosIndex = rowsTopPositions.findIndex((pos) => pos === obj.top)
          obj.set('top', updatedRowTopPositions[topPosIndex])
        }
      })
    }
  },

  _changeAnswersGroupHeight: function (answersGroup) {
    const updatedAnswersGroupObjects = answersGroup.getObjects()
    const firstAnswerObj = updatedAnswersGroupObjects[0]
    const lastAnswerObj = updatedAnswersGroupObjects[updatedAnswersGroupObjects.length - 1]
    const updatedAnswersGroupHeight = lastAnswerObj.top - firstAnswerObj.top + lastAnswerObj.height
    const groupHeightDiff = answersGroup.height - updatedAnswersGroupHeight
    answersGroup.set({ height: updatedAnswersGroupHeight })
    updatedAnswersGroupObjects.forEach((obj) => {
      const objTop = obj.top + groupHeightDiff / 2
      obj.set('top', objTop)
    })
  },

  _alignLastRowToCenter: function (answersGroup) {
    const answersGroupObjects = answersGroup.getObjects()
    const lastAnswerObj = answersGroupObjects[answersGroupObjects.length - 1]
    const answersRow = answersGroupObjects.filter((obj) => obj.top === lastAnswerObj.top)
    const isCompleteRow = answersRow.length === HORIZONTAL_LAYOUT_ROW_LENGTH
    if (isCompleteRow) return
    this._alignRowToCenter(answersRow)
  },

  _alignRowToCenter: function (answersRow) {
    const answersCount = answersRow.length
    const gapCount = answersCount - 1
    const rowWidth = HORIZONTAL_LAYOUT_ANSWER_WIDTH * answersCount + HORIZONTAL_LAYOUT_ANSWERS_GAP * gapCount
    answersRow.forEach((obj, index) => {
      const firstObjLeftPos = -rowWidth / 2
      const leftPos = firstObjLeftPos + (HORIZONTAL_LAYOUT_ANSWER_WIDTH + HORIZONTAL_LAYOUT_ANSWERS_GAP) * index
      obj.set({ left: leftPos })
    })
  },

  _drawAnswers: function () {
    const answersGroup = this.patternSourceCanvas.getObjects()[0]
    this.patternSourceCanvas.setDimensions({
      width: answersGroup.getScaledWidth(),
      height: answersGroup.getScaledHeight(),
    })
    this.patternSourceCanvas.renderAll()
  },

  set: function (key, value) {
    if (key === 'questionText') {
      this._setQuestionText(value)
      return this
    } else if (key === 'markCorrectAnswer') {
      this._markCorrectAnswer(value)
      return this
    } else if (key === 'unmarkCorrectAnswer') {
      this._unmarkCorrectAnswer(value)
      return this
    } else if (key === 'answerInteractivity') {
      this._setAnswerInteractivity(value)
      return this
    } else if (key === 'answerText') {
      this._setAnswerText(value)
      return this
    } else if (key === 'addAnswer') {
      this._addAnswer()
      return this
    } else if (key === 'removeAnswer') {
      this._removeAnswer(value)
      return this
    }
    if (typeof key === 'object') this._setObject(key)
    else this._set(key, value)
    return this
  },

  toObject: function () {
    const serializedObjects = []
    this.forEachObject((obj) => serializedObjects.push(obj.toObject(QUESTION_PROPS_TO_INCLUDE)))
    const answersObj = this.patternSourceCanvas.getObjects()[0].toObject(QUESTION_PROPS_TO_INCLUDE)
    serializedObjects.push(answersObj)
    return fabric.util.object.extend(this.callSuper('toObject'), {
      id: this.id,
      animation: this.animation,
      correctAnswers: this.correctAnswers,
      layoutType: this.layoutType,
      theme: this.theme,
      feedback: this.feedback,
      onWrong: this.onWrong,
      onCorrect: this.onCorrect,
      objects: serializedObjects,
    })
  },
})

Question.fromObject = function (object, callback) {
  const options = { ...object }
  delete options.objects
  return fabric.util.enlivenObjects(object.objects, (enlivenObjects) => {
    return callback(new fabric.Question(enlivenObjects, options, true))
  })
}

Question.createQuestion = function (questionObject, canvas, callback) {
  const options = { ...questionObject }
  delete options.objects
  return fabric.util.enlivenObjects(questionObject.objects, (enlivenObjects) => {
    const answersGroup = enlivenObjects.find((obj) => obj.question.element === 'answers_group')
    const answersGroupObjects = answersGroup.getObjects()
    answersGroupObjects.forEach((obj) => {
      if (obj.question.element === 'answer') obj.set({ question: { ...obj.question, answerId: uniqueId() } })
    })
    const currentQuestionObj = canvas.getObjects().find((obj) => obj.type === 'question')
    const newQuestionObj = new fabric.Question(
      enlivenObjects,
      {
        id: uniqueId(),
        correctAnswers: [answersGroupObjects[0].question.answerId],
        ...options,
      },
      true,
    )
    callback(newQuestionObj)
    if (currentQuestionObj) {
      this.replaceContent(currentQuestionObj, newQuestionObj)
      canvas.remove(currentQuestionObj)
    }
  })
}

Question.replaceContent = function (currentQuestionObj, newQuestionObj) {
  const questionTextObj = currentQuestionObj.getObjects().find((obj) => obj.question.element === 'question')
  newQuestionObj.set('questionText', questionTextObj.text)

  const currentQuestionAnswers = currentQuestionObj.patternSourceCanvas.getObjects()[0].getObjects()
  const newQuestionAnswers = newQuestionObj.patternSourceCanvas.getObjects()[0].getObjects()
  if (currentQuestionAnswers.length > newQuestionAnswers.length) {
    let i = 0
    const count = currentQuestionAnswers.length - newQuestionAnswers.length
    while (i < count) {
      newQuestionObj.set('addAnswer')
      i++
    }
  } else if (currentQuestionAnswers.length < newQuestionAnswers.length) {
    newQuestionObj.set('removeAnswer', newQuestionAnswers[0].question.answerId)
  }

  const updatedNewQuestionAnswers = newQuestionObj.patternSourceCanvas.getObjects()[0].getObjects()
  updatedNewQuestionAnswers.forEach((answer, index) => {
    const textObj = currentQuestionAnswers[index].getObjects().find((obj) => obj.question.element === 'answer_text')
    newQuestionObj.set('answerText', { id: answer.question.answerId, text: textObj.text })
  })

  const indexOfFirstCorrectAnswer = currentQuestionAnswers.findIndex((answer) =>
    answer.getObjects().find((obj) => obj.question.element === 'answer_checkbox' && obj.question.state === 'checked'),
  )
  const newQuestionCorrectAnswer =
    updatedNewQuestionAnswers[indexOfFirstCorrectAnswer === -1 ? 0 : indexOfFirstCorrectAnswer]
  newQuestionObj.set('markCorrectAnswer', newQuestionCorrectAnswer.question.answerId)

  newQuestionObj.animation = currentQuestionObj.animation
  newQuestionObj.feedback = currentQuestionObj.feedback
  newQuestionObj.onWrong = currentQuestionObj.onWrong
  newQuestionObj.onCorrect = currentQuestionObj.onCorrect
}

export default Question
