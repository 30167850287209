import { useNavigate } from 'react-router-dom'
import { useState, useMemo, useRef, useEffect } from 'react'
import { Row, Col, Modal, Button, Select } from 'antd'

import { useStore } from '../store'

import { LONG_RENDER_TIME_MS } from '../utils/constants'
import { useElaiNotification } from '../hooks/useElaiNotification'
import { requestTranslateVideo } from '../utils/videoCreation/videoCreation'

const flagsUrl = 'https://d3u63mhbhkevz8.cloudfront.net/flags/'

export default (props) => {
  const { video, isOpen, setIsOpen } = props

  const [loading, setLoading] = useState(false)
  const [language, setLanguage] = useState({ name: null, code: null, voices: null })
  const [requestError, setRequestError] = useState(null)
  const [isTranslatingTooLong, setIsTranslatingTooLong] = useState(false)
  const timerRef = useRef(null)

  const navigate = useNavigate()
  const notification = useElaiNotification()

  const voices = useStore((stores) => stores.voicesStore.voices)

  useEffect(() => {
    return () => (timerRef.current !== null ? clearTimeout(timerRef.current) : null)
  }, [])

  const translate = async () => {
    setLoading(true)
    timerRef.current = setTimeout(() => setIsTranslatingTooLong(true), LONG_RENDER_TIME_MS)
    const translated = await requestTranslateVideo({
      videoId: video._id,
      data: { language },
      customErrorHandler: setRequestError,
    })
    setLoading(false)
    clearTimeout(timerRef.current)
    timerRef.current = null
    if (!translated) return
    setIsOpen(false)
    navigate(`/video/${translated._id}`)
  }

  useEffect(() => {
    if (!requestError) return
    console.log('Request Error', requestError)
    if (requestError.code === 'ETIMEDOUT') return
    if (requestError.code === 'ERR_NETWORK') return
    if (requestError.response?.status === 504) return
    notification.error({
      message: requestError.response?.data?.message ?? requestError.response?.statusText ?? requestError.message,
      duration: null,
      key: 'translate-api-error',
      onClose: () => setRequestError(null),
    })
  }, [requestError])

  const relevantVoices = useMemo(() => {
    if (!video) {
      return []
    }
    const videoLanguages = [...new Set(video.slides.filter((s) => s.voiceType === 'text').map((s) => s.language))]
    return voices.filter(
      (language, idx) =>
        videoLanguages.indexOf(language.name) === -1 && voices.findIndex((v) => v.name === language.name) === idx,
    )
  }, [video])

  const onChangeLanguage = (lang) => {
    const voice = voices.find((voice) => voice.name === lang)
    setLanguage({
      name: lang,
      code: voice['male'][0].locale.split('-').slice(0, 2).join('-'),
      voices: { male: voice['male'][0].voice, female: voice['female'][0].voice },
    })
  }
  const handleClose = () => {
    setIsTranslatingTooLong(false)
    setIsOpen(false)
  }

  if (!video) return null
  return (
    <Modal
      title={`Translate ${video.name}`}
      centered
      forceRender
      open={isOpen}
      width={500}
      bodyStyle={{ padding: 15 }}
      footer={
        isTranslatingTooLong ? null : (
          <Button
            type="primary"
            key="translate"
            onClick={translate}
            disabled={loading || !language.name}
            loading={loading}
          >
            Translate
          </Button>
        )
      }
      onCancel={handleClose}
    >
      {isTranslatingTooLong ? (
        <p className="translate-long-text">
          Sorry, it's taking longer than usual to translate the video. Please refresh this page. Your video will appear
          in your "Videos" section soon.
        </p>
      ) : (
        <Row gutter={12}>
          <Col xs={24}>
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Select a language"
              className="language-select"
              value={language.name}
              onChange={onChangeLanguage}
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {relevantVoices.map((language) => (
                <Select.Option
                  key={language.name.toLowerCase()}
                  value={language.name}
                  className="language-select-option"
                >
                  <img
                    src={`${flagsUrl}${language.male[0].icon}.svg`}
                    width="20"
                    className="flag-icon"
                    style={{ marginRight: 10 }}
                    alt={language.name}
                  />
                  <span>{language.name}</span>
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      )}
    </Modal>
  )
}
