import { notification, Form } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { request } from '../../../utils/api'

import { COURSE_URL } from './constants'

export const useCoursesState = () => {
  const navigate = useNavigate()

  const [courses, setCourses] = useState([])
  const [userVideos, setUserVideos] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editableCourse, setEditableCourse] = useState({})

  const [courseForm] = Form.useForm()

  // fetch courses once on initial load
  useEffect(() => {
    const fetchCourses = async () => {
      const result = await request({ method: 'get', url: COURSE_URL })
      setCourses(result)
    }

    const fetchVideos = async () => {
      const data = await request({
        method: 'post',
        url: 'videos/lookup',
        data: { match: { deleted: false } },
      })
      setUserVideos(data.videos)
    }

    fetchCourses()
    fetchVideos()
  }, [])

  const handleCreateCourse = () => {
    courseForm.resetFields()
    setEditableCourse({})
    setIsModalOpen(true)
  }

  const deleteCourse = (record) => async () => {
    const result = await request({ method: 'delete', url: `${COURSE_URL}/${record._id}` })
    if (result === false) return
    notification.success({ message: 'Course was successfully removed ', duration: 4 })
    // update state
    setCourses((prevState) => [...prevState.filter((course) => course._id !== record._id)])
  }

  const editCourse = (record) => () => {
    setEditableCourse(record)
    setIsModalOpen(true)
  }

  const navigateToLessons = (recordId) => () => {
    navigate(`/admin/courses/${recordId}`)
  }

  return {
    courses,
    setCourses,
    editCourse,
    courseForm,
    userVideos,
    isModalOpen,
    deleteCourse,
    editableCourse,
    setIsModalOpen,
    navigateToLessons,
    setEditableCourse,
    handleCreateCourse,
  }
}
