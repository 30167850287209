export const LONG_RENDER_TIME_MS = 40 * 1000
export const osMap = { WINDOWS: 'Windows', MAC: 'Mac', LINUX: 'Linux' }
export const ACCOUNT_PLAN = { BASIC: 'basic', ADVANCED: 'advanced', UNLIMITED: 'unlimited', CUSTOM: 'custom' }
export const defaultTemplateId = '637b5c563923fa041a039421'
export const defaultBlankVideoName = 'Blank video'
export const maxVideoNameLength = 100

export const tutorialVideos = {
  interactiveVideos: {
    thumbnail: 'https://img.youtube.com/vi/ffhi524ZO_c/sddefault.jpg',
    url: 'https://www.youtube.com/watch?v=ffhi524ZO_c',
    name: 'Interactive videos',
  },
  foldersAndTags: {
    thumbnail: 'https://img.youtube.com/vi/kwRG6sIk7zE/sddefault.jpg',
    url: 'https://www.youtube.com/watch?v=kwRG6sIk7zE',
    name: 'Folders and tags',
  },
  createTemplate: {
    thumbnail: 'https://img.youtube.com/vi/1ENKRznx94o/sddefault.jpg',
    url: 'https://www.youtube.com/watch?v=1ENKRznx94o',
    name: 'Create template',
  },
  inviteTeammate: {
    thumbnail: 'https://img.youtube.com/vi/sh3Bw6q5gS8/sddefault.jpg',
    url: 'https://www.youtube.com/watch?v=sh3Bw6q5gS8',
    name: 'Invite teammate',
  },
  assignRoles: {
    thumbnail: 'https://img.youtube.com/vi/CKpliqrZurc/sddefault.jpg',
    url: 'https://www.youtube.com/watch?v=CKpliqrZurc',
    name: 'Assign roles',
  },
  createAccount: {
    thumbnail: 'https://img.youtube.com/vi/SzxSAZXt-qQ/sddefault.jpg',
    url: 'https://www.youtube.com/watch?v=SzxSAZXt-qQ',
    name: 'Create account',
  },
  deleteAccount: {
    thumbnail: 'https://img.youtube.com/vi/WXwymB44rCU/sddefault.jpg',
    url: 'https://www.youtube.com/watch?v=WXwymB44rCU',
    name: 'Delete account',
  },
}
