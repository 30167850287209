import { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Layout, Modal, Card, Row, Col, Spin } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { MAX_CARD_DESCRIPTION_CHARS, STYLE, PUNCTUATION_MARKS_REGEX } from './constants'
import { request } from '../../utils/api'
import useIntersection from '../../hooks/useIntersection'
import Icon from '../../components/Icon'
import './academy.less'

const Academy = () => {
  const navigate = useNavigate()
  const welcomeVideoRef = useRef(null)
  const coursesObserverTarget = useRef(null)
  const [isOpenVideoModal, setIsOpenVideoModal] = useState(false)
  const [coursesData, setCoursesData] = useState(null)
  const [courses, setCourses] = useState([])
  const [loadingCourses, setLoadingCourses] = useState(false)

  const onClickPlayButton = () => {
    setIsOpenVideoModal(true)
  }

  const handleCancel = () => {
    welcomeVideoRef.current.pause()
    setIsOpenVideoModal(false)
  }

  const fetchCoursesData = async (page) => {
    setLoadingCourses(true)
    const coursesRes = await request({ method: 'get', url: `public/courses?page=${page}` })
    if (coursesRes) {
      setCoursesData(coursesRes)
      setCourses((data) => (coursesRes.page === 1 ? coursesRes.courses : data.concat(coursesRes.courses)))
    }
    setLoadingCourses(false)
  }

  const showMoreCourses = async () => {
    if (!coursesData || coursesData.page === coursesData.pages) return
    await fetchCoursesData(coursesData.page + 1)
  }

  useIntersection(coursesObserverTarget, showMoreCourses)

  useEffect(() => {
    fetchCoursesData(1)
  }, [])

  return (
    <Layout.Content className="elai-academy">
      <div className="academy-content-wrapper">
        <div className="academy-content">
          <div className="title-block">
            <div className="title-block-content">
              <h1>Welcome to Elai Academy</h1>
              <p className="description">
                Our diverse range of courses is designed to empower you with the knowledge and skills needed for success
                in today's dynamic world.
              </p>
              <Button
                type="primary"
                href="#courses"
                icon={<RightOutlined />}
                className="btn-arrow btn-arrow-xl"
                size="large"
              >
                Start learning
              </Button>
            </div>
            <Button
              className="btn-play-circle"
              size="large"
              shape="circle"
              icon={<Icon name="play_filled" />}
              onClick={onClickPlayButton}
            ></Button>
          </div>
          <div className="courses-list-section">
            <h2 className="section-title" id="courses">
              Explore our courses
            </h2>
            <Row
              gutter={[
                { xs: 24, sm: 24, md: 16, lg: 32, xl: 32, xxl: 32 },
                { xs: 24, sm: 24, md: 16, lg: 32, xl: 32, xxl: 32 },
              ]}
            >
              {courses?.map((course) => (
                <Col key={course.id} xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                  <Card
                    className="course-card"
                    hoverable
                    cover={<img alt="Course thumbnail" src={course.thumbnail} />}
                    onClick={() => navigate(`/academy/course-preview/${course.id}`)}
                  >
                    <Card.Meta
                      title={course.name}
                      description={
                        <>
                          <p>
                            {course.description.length > MAX_CARD_DESCRIPTION_CHARS
                              ? course.description.split(PUNCTUATION_MARKS_REGEX)?.[0]
                              : course.description}
                          </p>
                          <Button className="btn-link" type="link">
                            Learn more <Icon name="right_arrow_full" />
                          </Button>
                        </>
                      }
                    />
                  </Card>
                </Col>
              ))}
              {loadingCourses ? (
                <Spin className="spin-centered" />
              ) : courses.length ? (
                <div ref={coursesObserverTarget} style={STYLE.height10}></div>
              ) : null}
            </Row>
          </div>
        </div>
      </div>
      <Modal className="welcome-video-modal" open={isOpenVideoModal} onCancel={handleCancel} footer={null} width={900}>
        <video ref={welcomeVideoRef} src="" controls controlsList="nodownload noremoteplayback"></video>
      </Modal>
    </Layout.Content>
  )
}

export default Academy
