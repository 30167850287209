import React, { useEffect } from 'react'
import axios from 'axios'
import { Spin } from 'antd'
import { useNavigate } from 'react-router-dom'
import { apiUrl } from '../../utils/config'
import { getAuthHeaders } from '../../utils/api'
import { useLocation, useParams } from 'react-router'
import { useStore } from '../../store'
import { useElaiNotification } from '../../hooks/useElaiNotification'

export default () => {
  const notification = useElaiNotification()
  const { search } = useLocation()
  const authStore = useStore((stores) => stores.authStore)
  const navigate = useNavigate()
  const { integration } = useParams()
  const params = new URLSearchParams(search)
  const code = params.get('code')

  const ssoAuth = async () => {
    try {
      const { data } = await axios.get(apiUrl + '/auth/sso/callback' + search, {
        headers: { ...(authStore.user ? getAuthHeaders() : {}) },
        withCredentials: true,
      })
      // Clear user data like subAccountId to ensure we get the correct user state
      authStore.logout()
      await authStore.login(data)
      const redirectUrl = localStorage.getItem('redirectUrl') || data.redirectUrl
      localStorage.removeItem('redirectUrl')
      if (redirectUrl) window.location.href = redirectUrl
      else {
        notification.success({
          message: data.message,
        })
        navigate('/')
      }
    } catch (error) {
      const message = error.response?.data?.message || error.message
      notification.error({ message, duration: false })
      navigate('/')
    }
  }

  useEffect(() => {
    if (integration) {
      const redirectUrl = params.get('redirect_url') || params.get('redirectUrl')
      if (redirectUrl) localStorage.setItem('redirectUrl', redirectUrl)
      window.location.href = apiUrl + '/auth/sso/login?integration=' + integration
    } else if (code) {
      ssoAuth()
    } else {
      const params = new URLSearchParams(window.location.search)
      const error = params.get('error')
      const errorDescription = params.get('error_description')
      if (error) {
        notification.error({
          message: error,
          description: errorDescription || 'Unknown error',
          duration: false,
        })
      }
      navigate('/')
    }
  }, [])

  return <Spin size="large" />
}
