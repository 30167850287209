import { Button, Modal } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import ContentEditable from 'react-contenteditable'
import Scrollbars from 'react-custom-scrollbars'
import { inputFieldsPlaceholders } from '../constants'

export const OutlineModal = ({
  outlineList,
  isOpenOutlineModal,
  setIsOpenOutlineModal,
  gptLoading,
  setGptLoading,
  setGptInputValue,
  handleSubmitGptInput,
  applyVideoEnhancement,
  handleClickField,
}) => {
  const handleConfirmOutlineModal = async () => {
    setGptLoading({ key: 'create-from-outline', message: 'Generating content', isLoading: true })
    const enhancedVideo = await applyVideoEnhancement('create-by-outline', { list: outlineList.current })
    setGptLoading({ isLoading: false })
    if (!enhancedVideo) return
    setIsOpenOutlineModal(false)
    setGptInputValue('')
    outlineList.current = null
  }

  const handleInputOutlineList = (e) => {
    const childNodes = [...e.currentTarget.children]
    const texts = []
    childNodes.forEach((child) => {
      if (child.tagName === 'LI') texts.push(child.innerText)
    })
    outlineList.current = texts
  }

  const handleKeyDownOutlineList = (e) => {
    if (!e.target.innerText || e.target.innerText === '\n') {
      e.preventDefault()
      e.target.innerHTML = `<li><span class="placeholder">${inputFieldsPlaceholders.list}</span></li>`
    } else if (e.target.innerText === inputFieldsPlaceholders.list) {
      if (e.key === 'Backspace' || e.key === 'Delete') e.preventDefault()
      else e.target.innerHTML = `<li></li>`
    }
  }

  return (
    <Modal
      centered
      open={isOpenOutlineModal}
      width={400}
      className="outline-modal"
      footer={[
        <Button
          loading={gptLoading.key === 'create-outline'}
          key="regenerate"
          icon={<SyncOutlined />}
          onClick={handleSubmitGptInput}
        >
          Regenerate outline
        </Button>,
        <Button
          loading={gptLoading.key === 'create-from-outline'}
          key="continue"
          type="primary"
          onClick={handleConfirmOutlineModal}
        >
          Create slides
        </Button>,
      ]}
      onCancel={() => setIsOpenOutlineModal(false)}
    >
      <h2>This is your video outline</h2>
      <p className="list-input-label">
        Each row will be used to generate a content for a separate slide. Feel free to edit it or regenerate before
        creating slides.
      </p>
      <Scrollbars className="scrollbar" style={{ height: 300 }}>
        <ContentEditable
          html={
            outlineList.current?.length
              ? outlineList.current.map((item) => `<li>${item.replaceAll?.(/\n|\r\n/g, '')}</li>`).join('')
              : `<li><span class="placeholder">${inputFieldsPlaceholders.list}</span></li>`
          }
          data-name="list-outline"
          tagName="ul"
          className="list-input"
          suppressContentEditableWarning={true}
          onChange={handleInputOutlineList}
          onClickCapture={(e) => {
            if (e.target.tagName === 'UL') return
            handleClickField(e)
          }}
          onKeyDown={handleKeyDownOutlineList}
        />
      </Scrollbars>
    </Modal>
  )
}
